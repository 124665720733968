import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
// Import neccessary site components, try to maintain alphabetical order
import Specialties from 'components/specialties/';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class SpecialtiesController extends Component {
	
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/specialty/all/?_join=true`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let specialties = [];
					data.forEach(specialty => {
						specialties.push(this.processData(specialty));
					});
					specialties.sort((a, b) => {
						let fa = a.title.toLowerCase(),
							fb = b.title.toLowerCase();

						if (fa < fb) {
							return -1;
						}
						if (fa > fb) {
							return 1;
						}
						return 0;
					});
					this.setState({
						status: STATUS.LOADED,
						data: specialties,
					})
				}
			})
			.catch(err => console.log);
	}

	processData(data) {
		let newDatum = {
			title: data.name,
			link: data.uri,
		}
		return newDatum;
	}

	constructor(props) {
		super(props);

		let initialData = [];
		if (props.data && props.data.allPhysiciansSpecialty && props.data.allPhysiciansSpecialty.edges.length) {
			props.data.allPhysiciansSpecialty.edges.forEach(physician => {
				initialData.push(this.processData(physician.node));
			});
		}

		initialData.sort((a, b) => {
			let fa = a.title.toLowerCase(),
				fb = b.title.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});

		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
		this.processData = this.processData.bind(this);

	}	

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<Specialties data={this.state.data}/>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	id
			      	name
			      	uri
			      }
			    }
			  }
			}
		`}
		render={data => (<SpecialtiesController data={data} {...props} />)}
	/>
)