import React, { Component } from "react"
import { graphql } from 'gatsby'
// import $ from 'jquery';
// import { Router } from "@reach/router"
import CTA from 'components/cta'
import Layout from 'components/layout/basic';
import MastheadInterior from 'components/masthead/interior'
import SpecialtiesController from 'controllers/specialties/specialties';
import SEO from 'shared/seo';

export default class LocationsPage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<Layout>
					<MastheadInterior title="Specialties" intro="Explore our comprehensive specialty care and services by clicking on any of the specialties below." />
					<hr />
					<SpecialtiesController />
					<hr />
					<CTA title="Symptoms and Conditions Guide" icon="medical" link="/symptoms-conditions-guide/" cta="View Guide" description="Our Symptoms and Conditions Guide is designed to help you recognize the medical symptoms you’re experiencing and identify the proper care path. Addressing symptoms properly can assist in a faster recovery and even save your life. Depending on your symptoms, you’ll be instructed to care for yourself at home, contact your physician, or go to the Emergency Room." />
					<SEO title={this.shareTitle} description={this.shareDescription} image={this.shareImage}/>
				</Layout>
			</>
		)
	}
}