import React, {Component} from 'react'
import style from './specialties.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Icon from 'components/icon'
import Link from 'shared/link';

class Specialties extends Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	render() {
		return(
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className="cell">
						<h3 className={style.title}>{(this.props.title) ? this.props.title : 'Specialty Categories'}</h3>
					</div>
				</div>
				<div className={[style.specialtiesGrid, "grid-x medium-up-2"].join(' ')}>
					{this.data.map((item, index) => (
						<div key={index} className={[style.specialty, 'cell'].join(' ')}>
							<Link className={style.inner} to={item.link}>{item.title}<Icon className={style.arrow} icon="rightArrow" /></Link>
						</div>
					))}
				</div>
			</div>
		)
	}
}

export default Specialties;